var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "voicemails-card" },
        [
          !_vm.voicemailsFinished
            ? _c("ct-centered-spinner", { attrs: { height: "200px" } })
            : _vm._e(),
          _vm.voicemailsFinished && _vm.selectedVoicemail
            ? _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "delete-voicemail",
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.downloadVoicemail },
                    },
                    [_vm._v("\n        Download\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "delete-voicemail",
                      attrs: { variant: "outline-danger" },
                      on: { click: _vm.deleteVoicemail },
                    },
                    [_vm._v("\n        Delete\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "delete-voicemail",
                      attrs: { variant: "outline-success" },
                      on: {
                        click: function ($event) {
                          return _vm.callBack(_vm.selectedVoicemail.from_number)
                        },
                      },
                    },
                    [_vm._v("\n        Call Back\n      ")]
                  ),
                  _c("span", { staticClass: "faint-header" }, [
                    _vm._v("Voicemail from:"),
                  ]),
                  _c("span", { staticClass: "caller" }, [
                    _vm._v(
                      _vm._s(
                        _vm.contactNameOrPhoneNumber(
                          _vm.people,
                          _vm.selectedVoicemail.from_number.substring(2),
                          "Unknown Caller",
                          _vm.isNotAccountOwner
                        )
                      ) + "\n        "
                    ),
                    !this.isNotAccountOwner
                      ? _c("span", {
                          directives: [
                            {
                              name: "phone-number",
                              rawName: "v-phone-number",
                              value: _vm.selectedVoicemail.from_number,
                              expression: "selectedVoicemail.from_number",
                            },
                          ],
                          staticClass: "inbound-number",
                        })
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "additional-details" }, [
                    _vm._v(
                      "\n        For " +
                        _vm._s(_vm.selectedPhone.name) +
                        "\n        "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "phone-number",
                          rawName: "v-phone-number",
                          value: _vm.selectedVoicemail.to_number,
                          expression: "selectedVoicemail.to_number",
                        },
                      ],
                      staticClass: "ml-1",
                    }),
                    _c("span", {
                      directives: [
                        {
                          name: "datetime-long",
                          rawName: "v-datetime-long",
                          value: _vm.selectedVoicemail.call_ended_at,
                          expression: "selectedVoicemail.call_ended_at",
                        },
                      ],
                      staticClass: "extended-datetime",
                    }),
                  ]),
                  _c("hr", { staticClass: "w-100 mt-4 mb-4" }),
                  !_vm.isNotAccountOwner
                    ? _c(
                        "div",
                        [
                          _c("span", { staticClass: "faint-header" }, [
                            _vm._v("Voicemail Transcript:"),
                          ]),
                          _c("p", { staticClass: "transcript" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.selectedVoicemail.transcription) +
                                "\n        "
                            ),
                          ]),
                          _c("virtual-phone-voicemails-audio-playback", {
                            staticClass: "mt-2 pt-2",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }